<template>
    <div class="card-body d-flex justify-content-center align-items-center">
        <button class="btn btn-lg btn-success btn-wechat-pay"
                v-show="!qrCodeUrl"
                v-bind:class="{ 'btn-loading': isLoading }"
                v-on:click="triggerWeChatPay">
            <span class="mr-3">
                <font-awesome-icon v-bind:icon="['fab', 'weixin']"></font-awesome-icon>
            </span>
            <span>
                {{ $t("message.checkout.payment-methods.wechat-pay") }}
            </span>
        </button>
        <div class="text-center"
             v-show="qrCodeUrl">
            <qr-code class="qr-code"
                     v-bind:size="500"
                     v-bind:value="qrCodeUrl">
            </qr-code>
        </div>

        <b-modal centered
                 no-close-on-backdrop
                 no-close-on-esc
                 hide-header
                 hide-footer
                 v-model="paymentInProgress">
            <div class="payment-pending-backdrop d-flex flex-column justify-content-center align-items-center">
                <div class="sk-wandering-cubes">
                    <div class="sk-cube sk-cube1"></div>
                    <div class="sk-cube sk-cube2"></div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { BModal, } from "bootstrap-vue";
import QrCode from "../../QrCode";
export default {
    name: "WechatPay",
    components: {
        BModal,
        QrCode,
    },
    props: {
        "order": {
            type: Object,
            required: true,
        },
        "type": {
            type: String,
            required: true,
        },
        "response": {
            type: Object,
            required: true
        },
    },
    data () {
        return {
            isLoading: false,
            paymentInProgress: false,
            qrCodeUrl: ""
        }
    },
    computed: {

    },
    methods: {
        async triggerWeChatPay () {
            this.paymentFailed = false;
            this.paymentInProgress = true;
            this.isLoading = true;

            let session;
            try {
                session = await this.$store
                    .dispatch(
                        "wallet/addSession",
                        {
                            "paymentMethod": "wechat_pay",
                            "orderId": this.$route.params.orderId
                        }
                    );
                console.log(session);
            } catch (e) {
                console.log("wallet/addSession");
                console.error(e);
                return;
            }

            this.paymentInProgress = false;

            let result;
            try {
                result = await this.$stripe.confirmWechatPayPayment(
                    session.client_secret,
                    {
                        "payment_method_options": {
                            "wechat_pay": {
                                "client": "web",
                            },
                        }
                    },
                );
            } catch (e) {
                console.log("stripe/confirmWeChatPayPayment");
                console.error(e);
            }

            console.log("stripe/confirmWeChatPayPayment", result);

            if (result.error) {
                console.log(result.error.message);
                this.paymentFailed = true;
                this.isLoading = false;
            } else {
                if (result.paymentIntent.status === "succeeded") {
                    this.isLoading = false;
                    this.$router.push(
                        {
                            name: "checkout-completion",
                            params: {
                                "orderId": this.$route.params.orderId
                            }
                        }
                    );
                }
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.card-body {
    .btn-wechat-pay {
        min-width: 250px;
        background-color: #1AAD19;
        border-color: #2BA245;
        color: rgba(255, 255, 255, 1);
    }

    .qr-code {
        width: 200px;
        height: 200px;
    }
}
</style>
