<template>
    <div class="backdrop"
         v-bind:style="customStyle"
         v-bind:class="{ 'active': active }">
        <div class="btn-close m-3 p-1 position-absolute">
            <font-awesome-icon icon="times"></font-awesome-icon>
        </div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "Backdrop",
        props: {
            active: {
                type: Boolean,
                default: false,
            },
            customStyle: {
                type: Object
            }
        },
        data () {
            return {

            }
        },
        computed: {

        },
        methods: {

        }
    }
</script>

<style lang="scss" scoped>
    .backdrop {
        display: none;

        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;

        z-index: 100;

        background-color: transparent;
        backdrop-filter: blur(0);

        transition:
            background-color 400ms ease-in-out,
            backdrop-filter 400ms ease-in-out;

        &.active {
            display: flex;
            background-color: rgba(0, 0, 0, 0.4);
            backdrop-filter: blur(2px);

            & > * {
                flex: 1;
            }
        }

        .btn-close {
            cursor: pointer;
            top: 0;
            right: 0;
            color: rgba(255, 255, 255, 1);
            font-size: 24px;
        }
    }
</style>
