<template>
    <b-modal centered
             v-bind:title="$t('message.checkout.add-card.title')"
             v-model="value"
             v-on:hidden="closeModal"
             v-on:close="closeModal"
             v-on:cancel="resetNewCard">
        <div class="row">
            <div class="col-md-12 mb-3">
                <label>
                    {{ $t("message.checkout.add-card.card-name") }}
                </label>
                <input type="text"
                       class="form-control rounded-0"
                       required
                       v-model="card.name">
                <small class="text-muted">
                    {{ $t("message.checkout.add-card.card-name-hint") }}
                </small>
                <div class="invalid-feedback">
                    Name on card is required
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 mb-3">
                <label>
                    {{ $t("message.checkout.add-card.card-number") }}
                </label>
                <card-number class="stripe-element card-number"
                             ref="cardNumber"
                             v-bind:stripe="$stripe"
                             v-bind:options="stripeOptions"
                             v-on:change="card.number = $event.complete">
                </card-number>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 mb-3">
                <label>
                    {{ $t("message.checkout.add-card.card-expiry") }}
                </label>
                <card-expiry class="stripe-element card-expiry"
                             ref="cardExpiry"
                             v-bind:stripe="$stripe"
                             v-bind:options="stripeOptions"
                             v-on:change="card.expiry = $event.complete">
                </card-expiry>
            </div>
            <div class="col-md-6 mb-3">
                <label>
                    {{ $t("message.checkout.add-card.card-cvc") }}
                </label>
                <card-cvc class="stripe-element card-cvc"
                          ref="cardCvc"
                          v-bind:stripe="$stripe"
                          v-bind:options="stripeOptions"
                          v-on:change="card.cvc = $event.complete">
                </card-cvc>
            </div>
        </div>

        <div class="text-center text-danger">
            {{ errorMessage }}
        </div>

        <template slot="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    v-on:click="resetNewCard">
                {{ $t("message.checkout.add-card.buttons.cancel") }}
            </button>
            <button type="button"
                    class="btn btn-primary"
                    v-bind:class="{ 'btn-loading': isLoading }"
                    v-bind:disabled="!allowAdd"
                    v-on:click="addCard">
                {{ $t("message.checkout.add-card.buttons.add") }}
            </button>
        </template>
    </b-modal>
</template>

<script>
import {
    BForm,
    BFormInput,
    BInputGroup,
    BModal,
} from "bootstrap-vue";

import {
    CardCvc,
    CardExpiry,
    CardNumber,
    createSource,
} from "vue-stripe-elements-plus";

export default {
    name: "ModalAddCard",
    components: {
        CardNumber,
        CardExpiry,
        CardCvc,
        createSource,
        BModal,
        BForm,
        BInputGroup,
        BFormInput,
    },
    props: {
        "value": {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            stripeOptions: {
                classes: {
                    base: "form-control rounded-0"
                }
            },

            isLoading: false,
            errorMessage: "",
            card: {
                name: "",
                number: false,
                expiry: false,
                cvc: false,
            },
        };
    },
    computed: {
        allowAdd () {
            return this.card.name &&
                this.card.number &&
                this.card.expiry &&
                this.card.cvc;
        },
    },
    methods: {
        closeModal () {
            this.$emit("input", false);
        },
        async addCard () {
            this.isLoading = true;
            let source;
            try {
                source = await createSource(
                    {
                        owner: {
                            name: this.card.name,
                        },
                    }
                );
            } catch ({ error }) {
                console.error(error);
                this.isLoading = false;
                this.errorMessage = error.message;
                this.resetNewCard();
                return;
            }

            if (source.error) {
                this.isLoading = false;
                this.errorMessage = source.error.message;
                this.reset();
                return;
            }

            try {
                await this.$store.dispatch(
                    "wallet/addCard",
                    source.source.id,
                );
            } catch (e) {
                console.error(e);
                this.isLoading = false;
                return;
            }

            this.isLoading = false;
            this.resetNewCard();
        },

        reset () {
            this.card = {
                name: "",
                number: false,
                expiry: false,
                cvc: false,
            };
        },

        resetNewCard () {
            this.reset();
            this.closeModal();
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
