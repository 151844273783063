<template>
    <div class="card-body">
        <div class="container"
             v-if="shop.paymentMethods.payment_request_api">
            <b-collapse v-model="isPaymentRequestAPIShown">
                <payment-request-button label="marier Skincare"
                                        currency="hkd"
                                        v-bind:amount="orderTotal"
                                        v-on:ready="isPaymentRequestAPIShown = true">
                </payment-request-button>

                <div class="d-flex justify-content-between align-items-center my-4"
                     v-if="shop.paymentMethods.cards && shop.paymentMethods.payment_request_api">
                    <hr class="flex-1 m-0">
                    <span class="flex-3 text-center">
                        {{ $t("message.checkout.payment-request-api-or-cards") }}
                    </span>
                    <hr class="flex-1 m-0">
                </div>
            </b-collapse>
        </div>

        <div class="container">
            <div class="row cards"
                 v-if="shop.paymentMethods.cards">
                <div class="col-lg-6 mb-4"
                     v-for="card in cards"
                     v-bind:key="card._id">
                    <card-selectable v-bind:card="card"
                                     v-on:click.native="selectCard(card)">
                    </card-selectable>
                </div>
                <div class="col-lg-6 mb-4">
                    <div class="add-card card h-100"
                         v-on:click="showAddCardModal = true">
                        <div class="card-body h-100 w-100 d-flex flex-column justify-content-center align-items-center">
                            <h2>
                                <font-awesome-icon icon="plus-circle"></font-awesome-icon>
                            </h2>
                            <h5>
                                {{ cards.length === 1 ? $t("message.checkout.add-card.title") : $t("message.checkout.add-another-card") }}
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="my-3">
                <button class="btn btn-dark btn-block"
                        v-bind:disabled="!allowNext"
                        v-on:click="payByCard">
                    {{ $t("message.checkout.next") }}
                </button>
            </div>
        </div>

        <modal-add-card v-model="showAddCardModal"></modal-add-card>

        <b-modal centered
                 no-close-on-backdrop
                 no-close-on-esc
                 hide-header
                 hide-footer
                 v-model="paymentInProgress">
            <div class="payment-pending-backdrop d-flex flex-column justify-content-center align-items-center">
                <div class="sk-wandering-cubes">
                    <div class="sk-cube sk-cube1"></div>
                    <div class="sk-cube sk-cube2"></div>
                </div>

                <h5 class="title"
                    v-if="!paymentFailed">
                    {{ $t("message.checkout.payment-in-progress") }}
                </h5>
                <h5 class="title"
                    v-else>
                    {{ $t("message.checkout.payment-failed") }}
                </h5>

                <p v-if="!paymentFailed">
                    <a class="btn btn-link" style="color: red; "
                       v-on:click="cancelPayment">
                        {{ $t("message.checkout.cancel-payment") }}
                    </a>
                </p>
                <p v-else>
                    <a class="btn btn-link" style="color: red; "
                       v-on:click="payByCard">
                        {{ $t("message.checkout.retry-payment") }}
                    </a>
                </p>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {
    BModal,
    BCollapse,
} from "bootstrap-vue";

import CardSelectable from "./Cards/CardSelectable";
import PaymentRequestButton from "./Cards/PaymentRequestButton";

import ModalAddCard from "./Cards/ModalAddCard";

export default {
    name: "PaymentCards",
    components: {
        ModalAddCard,
        PaymentRequestButton,
        CardSelectable,
        BModal,
        BCollapse,
    },
    props: {
        "order": {
            type: Object,
            required: true,
        },
        "type": {
            type: String,
            required: true,
        },
        "response": {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            isLoading: false,
            isPaymentRequestAPIShown: false,

            paymentFailed: false,

            showAddCardModal: false,
            paymentInProgress: false,
            paymentWindow: null,

            cards: [],
            selectedCard: null,
        }
    },
    computed: {
        allowNext () {
            return this.selectedCard;
        },
        deliveryClasses () {
            return this.$store.state.mall.shop.deliveryClasses;
        },
        shop () {
            switch (this.$route.params.type) {
                case "products": {
                    return this.$store.state.mall.shop;
                }
                case "treatments": {
                    return this.$store.state.mall.salon;
                }
            }
        },
        orderTotal () {
            if (!this.$route.params.type || !this.order) {
                return 0;
            }
            return parseFloat(this.order.orderTotal);
        },
    },
    methods: {
        selectCard (card) {
            this.cards.forEach(
                card => card.selected = false
            );
            card.selected = true;
            this.selectedCard = card;
        },
        cancelPayment () {
            if (this.paymentWindow) {
                this.paymentWindow.close();
                this.paymentWindow = null;
            }
            this.paymentFailed = false;
            this.paymentInProgress = false;
            this.isLoading = false;
        },
        async payByCard () {
            this.paymentFailed = false;
            this.paymentInProgress = true;
            this.isLoading = true;

            let session;
            try {
                session = await this.$store
                    .dispatch(
                        "wallet/addSession",
                        {
                            "paymentMethod": "cards",
                            "card": this.selectedCard.card.card,
                            "sourceId": this.selectedCard.card.id,
                            "customerId": this.selectedCard.card.customer,
                            "orderId": this.$route.params.orderId
                        }
                    );
                console.log(session);
            } catch (e) {
                console.log("wallet/addSession");
                console.error(e);
                return;
            }

            let result;
            try {
                result = await this.$stripe
                    .confirmCardPayment(
                        session.client_secret
                    );
            } catch (e) {
                console.log("wallet/addSession");
                console.error(e);
            }

            console.log("confirmCardPayment", result);

            if (result.error) {
                console.log(result.error.message);
                this.paymentFailed = true;
                this.paymentInProgress = false;
                this.isLoading = false;
            } else {
                if (result.paymentIntent.status === "succeeded") {
                    this.paymentInProgress = false;
                    this.isLoading = false;
                    this.$router.push(
                        {
                            name: "checkout-completion",
                            params: {
                                "orderId": this.$route.params.orderId
                            }
                        }
                    );
                }
            }
        }
    },
    mounted () {
        this.cards = this.$store.state.wallet.sources;
    },
    watch: {
        "$store.state.wallet.sources" (newVal) {
            this.cards = newVal;
        },
    }
}
</script>

<style lang="scss" scoped>
.card-body {
    color: rgba(113, 113, 113, 1);
}

.add-card {
    cursor: pointer;
}

.btn-loading {
    height: 40px;
    width: 100%;
}
</style>
