<template>
    <div class="card-body d-flex justify-content-center align-items-center">
        <div>
            <div id="paypal-button-container"
                 class="btn-paypal">
            </div>
        </div>
    </div>
</template>

<script>
//import PayPal from "vue-paypal-checkout";
export default {
    name: "PaymentPaypal",
    components: {
        //PayPal,
    },
    props: {
        "order": {
            type: Object,
            required: true,
        },
        "type": {
            type: String,
            required: true,
        },
    },
    data () {
        return {

        };
    },
    computed: {
        deliveryClasses () {
            return this.$store.state.mall.shop.deliveryClasses;
        },
        orderId () {
            return this.$route.params.orderId;
        },
        orderTotal () {
            if (!this.type || !this.order) {
                return 0;
            }
            return this.order.orderTotal;
        },
        isReady () {
            return this.orderTotal;
        },
    },
    methods: {
        renderButton () {
            paypal
                .Buttons(
                    {
                        "env": "production",
                        "client": this.$config.PaypalKeys,
                        "locale": "zh_HK",
                        createOrder: (data, actions) => {
                            return actions
                                .order
                                .create({
                                    "purchase_units": [{
                                        "amount": {
                                            "value": this.orderTotal
                                        }
                                    }]
                                });
                        },
                        onApprove: (data, actions) => {
                            return actions
                                .order
                                .capture()
                                .then(
                                    this.payPalPaymentCompleted
                                );
                        },
                    }
                )
                .render(
                    "#paypal-button-container",
                );
        },
        async payPalPaymentCompleted (res) {
            console.log(res);
            /*this.$store.dispatch(
                "wallet/setLocalSessionPaymentOptions",
                {
                    "paymentMethod": "paypal",
                    "orderId": this.$route.params.orderId,
                    "paypalResponse": Object.assign({}, res),
                    "id": res.id,
                }
            );*/

            let session;
            try {
                session = await this.$store.dispatch(
                    "wallet/addSession",
                    {
                        "paymentMethod": "paypal",
                        "orderId": this.orderId,
                        "paypalResponse": res,
                        "id": res.id,
                    }
                );
            } catch (e) {
                console.error(e);
                return;
            }

            try {
                await this.$store.dispatch("mall/loadOrders");
            } catch (e) {
                console.error(e);
                return;
            }

            this.$router.push({
                "name": "checkout-completion",
                "params": {
                    "orderId": this.orderId
                }
            });
        }
    },
    mounted () {
        this.renderButton();
        /*const paypalScript = document.createElement("script");
        paypalScript.src = `https://www.paypalobjects.com/api/checkout.js`;
        paypalScript.onload = () => {
            this.isPaypalLoaded = true;
        };
        document.body.appendChild(paypalScript);*/
    },
    watch: {
        isReady (newVal) {
            if (newVal === true) {
                this.renderButton();
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.btn-paypal {

}
</style>
