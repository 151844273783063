<template>
    <article class="card overflow-hidden shadow-sm h-100"
             v-bind:class="{ 'selected': card.selected }">
        <div class="card-header text-center p-2">
            <span v-if="!card.selected">
                {{ $t("message.checkout.card.select") }}
            </span>
            <span class="font-weight-bolder text-uppercase"
                  v-else>
                {{ $t("message.checkout.card.selected") }}
            </span>
        </div>
        <div class="card-body position-relative">
            <div class="cvv-verification position-absolute d-flex flex-column justify-content-center align-items-center p-4">
                <div class="input-group">
                    <input type="text"
                           class="form-control"
                           placeholder="Card CVV/CVC No."
                           v-model="cardCVC">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary"
                                type="button"
                                v-bind:disabled="!cardCVC"
                                v-bind:class="{ 'btn-loading': isLoading }"
                                v-on:click="verifyCardCVC">
                            Verify
                        </button>
                    </div>
                </div>
            </div>
            <dl>
                <dt>
                    <small>{{ $t("message.checkout.card.last-4-digit") }}</small>
                </dt>
                <dd>
                    {{ card.card.card.last4 }}
                </dd>

                <dt>
                    <small>{{ $t("message.checkout.card.valid-till") }}</small>
                </dt>
                <dd>
                    {{ card.card.card.exp_month }}/{{ card.card.card.exp_year }}
                </dd>

                <dt>
                    <small>{{ $t("message.checkout.card.name") }}</small>
                </dt>
                <dd>
                    {{ card.card.billing_details.name }}
                </dd>
            </dl>
        </div>

        <div class="card-footer p-0">
            <div class="btn-group d-flex">
                <button class="flex-1 btn btn-sm btn-outline-danger border-0 rounded-0 p-2"
                        v-on:click.stop="deleteCard">
                    {{ $t("message.checkout.card.delete") }}
                </button>
            </div>
        </div>
    </article>
</template>

<script>
export default {
    name: "CardSelectable",
    props: {
        card: {
            type: Object,
            required: true
        },
    },
    data () {
        return {
            cardCVC: "",
            isLoading: false,
        }
    },
    methods: {
        verifyCardCVC () {

        },

        async deleteCard () {
            this.isLoading = true;
            try {
                await this.$store
                    .dispatch(
                        "wallet/deleteCard",
                        this.card._id
                    );
            } catch (e) {
                console.log(e);
                this.isLoading = false;
                return;
            }
            this.isLoading = false;
        }
    },
    mounted() {
        console.log(this.card);
    }
}
</script>

<style lang="scss" scoped>
.card {
    cursor: pointer;
    user-select: none;

    .cvv-verification {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
        background-color: transparent;
        backdrop-filter: none;
        transition:
            backdrop-filter 200ms ease,
            background-color 200ms ease;

        & > .input-group {
            background-color: rgba(255, 255, 255, 1);
            opacity: 0;
            transition: opacity 200ms ease;
        }
    }

    .card-header,
    .card-footer {
        background-color: rgba(255, 255, 255, 1);
    }

    &.add-card {
        color: rgba(113, 113, 113, 1);
        background: rgba(240, 240, 240, 1);

        &:hover,
        &:active {
            color: rgba(0, 0, 0, 0.38);
            font-weight: bold;
        }
    }

    /*&.selected {
        .cvv-verification {
            background-color: rgba(255, 255, 255, 0.5);
            backdrop-filter: blur(2px);
            z-index: 1;

            & > .input-group {
                opacity: 1;
            }
        }
    }*/

    /*&.selected.verified {
        border-color: rgba(40, 167, 69, 1);

        .cvv-verification {
            background-color: transparent;
            backdrop-filter: none;
            z-index: -1;

            & > .input-group {
                opacity: 0;
            }
        }

        .card-header {
            background-color: rgba(40, 167, 69, 1);
            color: rgba(255, 255, 255, 1);
        }
    }*/

    &.selected {
        border-color: rgba(40, 167, 69, 1);

        .card-header {
            background-color: rgba(40, 167, 69, 1);
            color: rgba(255, 255, 255, 1);
        }
    }
}
</style>
