<template>
    <div class="card-body d-flex justify-content-center align-items-center">
        <button class="btn btn-lg btn-alipay"
                v-bind:class="{ 'btn-loading': isLoading }"
                v-on:click="triggerAlipay">
            <span class="mr-3">
                <font-awesome-icon v-bind:icon="['fab', 'alipay']"></font-awesome-icon>
            </span>
            <span>
                 {{ $t("message.checkout.payment-methods.alipay") }}
            </span>
        </button>

        <b-modal centered
                 no-close-on-backdrop
                 no-close-on-esc
                 hide-header
                 hide-footer
                 v-model="paymentInProgress">
            <div class="payment-pending-backdrop d-flex flex-column justify-content-center align-items-center">
                <div class="sk-wandering-cubes">
                    <div class="sk-cube sk-cube1"></div>
                    <div class="sk-cube sk-cube2"></div>
                </div>

                <h5 class="title"
                    v-if="!paymentFailed">
                    {{ $t("message.checkout.payment-in-progress") }}
                </h5>
                <h5 class="title"
                    v-else>
                    {{ $t("message.checkout.payment-failed") }}
                </h5>

                <p v-if="!paymentFailed">
                    <a class="btn btn-link" style="color: red; "
                       v-on:click="cancelPayment">
                        {{ $t("message.checkout.cancel-payment") }}
                    </a>
                </p>
                <p v-else>
                    <a class="btn btn-link" style="color: red; "
                       v-on:click="triggerAlipay">
                        {{ $t("message.checkout.retry-payment") }}
                    </a>
                </p>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { BModal, } from "bootstrap-vue";

import Backdrop from "../../Backdrop";
export default {
    name: "Alipay",
    components: {
        Backdrop,
        BModal,
    },
    props: {
        "order": {
            type: Object,
            required: true,
        },
        "type": {
            type: String,
            required: true,
        },
        "response": {
            type: Object,
            required: true
        },
    },
    data () {
        return {
            isLoading: false,
            paymentFailed: false,
            paymentInProgress: false,
        }
    },
    computed: {
        deliveryClasses () {
            return this.$store.state.mall.shop.deliveryClasses;
        },
        shop () {
            switch (this.$route.params.type) {
                case "products": {
                    return this.$store.state.mall.shop;
                }
                case "treatments": {
                    return this.$store.state.mall.salon;
                }
            }
        },
        orderTotal () {
            if (!this.$route.params.type || !this.order) {
                return 0;
            }
            return this.order.orderTotal;
        },
    },
    methods: {
        cancelPayment () {
            this.paymentFailed = false;
            this.paymentInProgress = false;
            this.isLoading = false;
        },
        async triggerAlipay () {
            this.paymentFailed = false;
            this.paymentInProgress = true;
            this.isLoading = true;

            let session;
            try {
                session = await this.$store
                    .dispatch(
                        "wallet/addSession",
                        {
                            "paymentMethod": "alipay",
                            "orderId": this.$route.params.orderId
                        }
                    );
                console.log(session);
            } catch (e) {
                console.log("wallet/addSession");
                console.error(e);
                return;
            }

            let result;
            try {
                result = await this.$stripe
                    .confirmAlipayPayment(
                        session.client_secret,
                        {
                            "return_url": `https://www.marierskincare.com/checkout/${ this.type }/complete/${ this.order._id }`,
                        }
                    );
            } catch (e) {
                console.log("stripe/confirmAlipayPayment");
                console.error(e);
            }

            console.log("stripe/confirmAlipayPayment", result);

            if (result.error) {
                console.log(result.error.message);
                this.paymentFailed = true;
                this.paymentInProgress = false;
                this.isLoading = false;
            } else {
                if (result.paymentIntent.status === "succeeded") {
                    this.paymentInProgress = false;
                    this.isLoading = false;
                    this.$router.push(
                        {
                            name: "checkout-completion",
                            params: {
                                "orderId": this.$route.params.orderId
                            }
                        }
                    );
                }
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.btn-alipay {
    min-width: 250px;
    background-color: #00A0E9;
    border-color: #17a2b8;
    color: rgba(255, 255, 255, 1);
}
</style>
