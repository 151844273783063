<template>
    <section>
        <div class="btn btn-dark btn-loading"
             v-show="isPlaceholderActive">
        </div>

        <div id="payment-request-button"
             v-show="!isPlaceholderActive">
        </div>

        <b-modal centered
                 no-close-on-backdrop
                 no-close-on-esc
                 hide-header
                 hide-footer
                 v-model="paymentInProgress">
            <div class="payment-pending-backdrop d-flex flex-column justify-content-center align-items-center">
                <div class="sk-wandering-cubes">
                    <div class="sk-cube sk-cube1"></div>
                    <div class="sk-cube sk-cube2"></div>
                </div>
                <h5 class="title"
                    v-if="!paymentFailed">
                    {{ $t("message.checkout.payment-in-progress") }}
                </h5>
                <h5 class="title"
                    v-else>
                    {{ $t("message.checkout.payment-failed") }}
                </h5>
            </div>
        </b-modal>
    </section>
</template>

<script>
import {
    BModal,
} from "bootstrap-vue";

export default {
    name: "PaymentRequestButton",
    components: {
        BModal,
    },
    props: {
        "currency": {
            type: String,
            required: true,
        },

        "amount": {
            type: Number,
            required: true,
        },

        "label": {
            type: String,
            required: true,
        }
    },
    data () {
        return {
            isPlaceholderActive: true,
            paymentInProgress: false,
        }
    },
    computed: {

    },
    async mounted () {
        let session;
        try {
            session = await this.$store
                .dispatch(
                    "wallet/addSession",
                    {
                        "paymentMethod": "payment_request_api",
                        "orderId": this.$route.params.orderId
                    }
                );
            console.log(session);
        } catch (e) {
            console.log("wallet/addSession");
            console.error(e);
            return;
        }

        const paymentRequest = this.$stripe
            .paymentRequest(
                {
                    "country": "HK",
                    "currency": this.currency,
                    "total": {
                        "label": this.label,
                        "amount": this.amount * 100,
                    },
                    "requestPayerName": true,
                    "requestPayerEmail": true,
                }
            );

        const PaymentRequestBtn = this.$stripe
            .elements()
            .create(
                "paymentRequestButton",
                {
                    paymentRequest,
                }
            );

        const result = await paymentRequest.canMakePayment();
        console.log(result);

        if (result) {
            PaymentRequestBtn.mount("#payment-request-button");
            this.isPlaceholderActive = false;
            this.$emit("ready");
        }

        paymentRequest.on(
            "paymentmethod",
            async evt => {
                let result;
                try {
                    result = await this.$stripe.confirmCardPayment(
                        session.client_secret,
                        { "payment_method": evt.paymentMethod.id, },
                        { "handleActions": false, }
                    );
                } catch (e) {
                    console.log("wallet/confirmCardPayment");
                    console.error(e);
                }

                console.log("confirmCardPayment", result);

                if (result.error) {
                    console.log(result.error.message);
                    this.paymentFailed = true;
                    this.paymentInProgress = false;
                    evt.complete("fail");
                    return;
                }

                evt.complete("success");

                if (paymentIntent.status === "requires_action") {
                    const { error } = await this.$stripe.confirmCardPayment(session.client_secret);
                    if (error) {
                        console.log(error);
                        return;
                    }
                }

                if (result.paymentIntent.status === "succeeded") {
                    this.paymentInProgress = false;
                    this.$router.push(
                        {
                            name: "checkout-completion",
                            params: {
                                "orderId": this.$route.params.orderId
                            }
                        }
                    );
                }
            }
        );
    },
}
</script>

<style lang="scss" scoped>
.btn-loading {
    height: 40px;
    width: 100%;
}
</style>
