<template>
    <div class="card-body d-flex justify-content-center align-items-center">
        <button class="btn btn-lg btn-dark btn-on-site"
                v-bind:class="{ 'btn-loading': isLoading }"
                v-on:click="next">
            <span class="mr-3">
                <font-awesome-icon icon="dollar-sign"></font-awesome-icon>
            </span>
            <span>
                {{ $t("message.checkout.payment-methods.pay-on-site") }}
            </span>
        </button>

        <b-modal centered
                 no-close-on-backdrop
                 no-close-on-esc
                 hide-header
                 hide-footer
                 v-model="paymentInProgress">
            <div class="payment-pending-backdrop d-flex flex-column justify-content-center align-items-center">
                <div class="sk-wandering-cubes">
                    <div class="sk-cube sk-cube1"></div>
                    <div class="sk-cube sk-cube2"></div>
                </div>
                <h5 class="title">
                    {{ $t("message.checkout.payment-in-progress") }}
                </h5>
                <p>
                    <a class="btn btn-link" style="color: red; "
                       v-on:click="paymentInProgress = false">
                        {{ $t("message.checkout.cancel-payment") }}
                    </a>
                </p>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {
    BModal,
} from "bootstrap-vue";

export default {
    name: "paymentOnSite",
    components: {
        BModal,
    },
    props: {
        "response": {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            loading: false,
            paymentInProgress: false,
        }
    },
    methods: {
        async next () {
            this.paymentInProgress = true;
            this.isLoading = true;

            this.$store.dispatch("wallet/setLocalSessionPaymentOptions", {
                paymentMethod: "pay_on_site",
            });

            let session;
            try {
                session = await this.$store.dispatch(
                    "wallet/addSession",
                    {
                        "paymentMethod": "pay_on_site",
                        "status": "completed",
                        "orderId": this.$route.params.orderId
                    }
                );
            } catch (e) {
                console.error(e);
                return;
            }

            try {
                await this.$store.dispatch("mall/loadOrders");
            } catch (e) {
                console.error(e);
                return;
            }

            this.paymentInProgress = false;
            this.$router.push({
                name: "Checkout-completion",
                params: {
                    orderId: this.$route.params.orderId
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.btn-on-site {
    min-width: 250px;
}
</style>
