<template>
    <section class="checkout-payment">
        <div class="mb-1" role="group"
             v-if="$route.params.type === 'treatments'">
            <label>
                <strong>{{ $t("message.checkout.email") }}</strong>
            </label>
            <b-form-input type="email"
                          v-bind:disabled="userStatus === 'loggedIn'"
                          v-model.trim="email">
            </b-form-input>
            <b-form-invalid-feedback>
                Enter at least 3 letters
            </b-form-invalid-feedback>
            <b-form-text>
                A confirmation will be sent to this email address.
            </b-form-text>
        </div>
        <div class="mb-3" role="group"
             v-if="$route.params.type === 'treatments'">
            <label>
                <strong>{{ $t("message.checkout.phone") }}</strong>
            </label>
            <b-form-input type="tel"
                          v-bind:disabled="userStatus === 'loggedIn'"
                          v-model.trim="phone">
            </b-form-input>
            <b-form-invalid-feedback>
                Enter at least 3 letters
            </b-form-invalid-feedback>
            <b-form-text>
                We may contact you via this phone number.
            </b-form-text>
        </div>
        <hr class="mb-4"
            v-if="$route.params.type === 'treatments'">
        <h4 class="mb-3">{{ $t("message.checkout.payment-method") }}</h4>

        <div class="container"
             v-if="shop.paymentMethods ? shop.paymentMethods.on_site : false">
            <p>
                {{ $t("message.checkout.pay-on-site-notice") }}
            </p>
        </div>

        <div class="card mb-3">
            <!-- <div class="card-header">
                <ul class="nav nav-tabs card-header-tabs">
                    <li class="nav-item"
                        v-if="shop.paymentMethods ? shop.paymentMethods.on_site : false"
                        v-bind:class="{ 'active': shop.paymentMethods.on_site }">
                        <router-link class="nav-link active"
                                     to="card">
                            <span class="mr-2">
                                <font-awesome-icon icon="dollar-sign"></font-awesome-icon>
                            </span>
                            <span>
                                {{ $t("message.checkout.payment-methods.pay-on-site") }}
                            </span>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">Link</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#">Link</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
                    </li>
                </ul>
            </div>
            <div class="card-body">
                <router-view></router-view>
            </div> -->

            <b-tabs card>
                <b-tab lazy
                       v-if="shop.paymentMethods ? shop.paymentMethods.on_site : false"
                       v-bind:active="shop.paymentMethods.on_site">
                    <template slot="title">
                        <span class="mr-2">
                            <font-awesome-icon icon="dollar-sign"></font-awesome-icon>
                        </span>
                        <span>
                            {{ $t("message.checkout.payment-methods.pay-on-site") }}
                        </span>
                    </template>
                    <payment-on-site v-bind:response.sync="paymentInfo"></payment-on-site>
                </b-tab>

                <b-tab lazy
                       v-if="shop.paymentMethods ? shop.paymentMethods.cards || shop.paymentMethods.payment_request_api : false"
                       v-bind:active="!shop.paymentMethods.on_site">
                    <template slot="title">
                        <span class="mr-2">
                            <font-awesome-icon icon="credit-card"></font-awesome-icon>
                        </span>
                        <span>
                            {{ $t("message.checkout.payment-methods.cards") }}
                        </span>
                    </template>
                    <payment-cards v-bind:order="order"
                                   v-bind:type="type"
                                   v-bind:response.sync="paymentInfo">
                    </payment-cards>
                </b-tab>

                <b-tab lazy
                       v-if="shop.paymentMethods ? shop.paymentMethods.paypal : false">
                    <template slot="title">
                        <span class="mr-2">
                            <font-awesome-icon v-bind:icon="['fab', 'paypal']"></font-awesome-icon>
                        </span>
                        <span>
                            {{ $t("message.checkout.payment-methods.paypal") }}
                        </span>
                    </template>
                    <payment-paypal v-bind:order="order"
                                    v-bind:type="type">
                    </payment-paypal>
                </b-tab>

                <b-tab lazy
                       v-if="shop.paymentMethods ? shop.paymentMethods.alipay : false">
                    <template slot="title">
                        <span class="mr-2">
                            <font-awesome-icon v-bind:icon="['fab', 'alipay']"></font-awesome-icon>
                        </span>
                        <span>
                            {{ $t("message.checkout.payment-methods.alipay") }}
                        </span>
                    </template>
                    <alipay v-bind:response.sync="paymentInfo"
                            v-bind:order="order"
                            v-bind:type="type">
                    </alipay>
                </b-tab>

                <b-tab lazy
                       v-if="shop.paymentMethods ? shop.paymentMethods.wechat_pay : false">
                    <template slot="title">
                        <span class="mr-2">
                            <font-awesome-icon v-bind:icon="['fab', 'weixin']"></font-awesome-icon>
                        </span>
                        <span>
                            {{ $t("message.checkout.payment-methods.wechat-pay") }}
                        </span>
                    </template>
                    <wechat-pay v-bind:response.sync="paymentInfo"
                                v-bind:order="order"
                                v-bind:type="type">
                    </wechat-pay>
                </b-tab>
            </b-tabs>
        </div>
    </section>
</template>

<script>
import {
    BFormText,
    BFormInvalidFeedback,
    BFormInput,
    BTabs,
    BTab,
} from "bootstrap-vue";

import PaymentCards from "./Payment/Cards";
import PaymentPaypal from "./Payment/Paypal";
import Alipay from "./Payment/Alipay";
import WechatPay from "./Payment/WechatPay";
import PaymentOnSite from "./Payment/OnSite";

export default {
    name: "Payment",
    components: {
        PaymentPaypal,
        PaymentOnSite,
        WechatPay,
        Alipay,
        PaymentCards,

        BFormText,
        BFormInvalidFeedback,
        BFormInput,
        BTabs,
        BTab,
    },
    props: {
        "type": {
            type: String,
        },

        "orderId": {
            type: String,
        },
    },
    data () {
        return {
            email: "",
            phone: "",
            paymentInfo: null
        }
    },
    computed: {
        user () {
            return this.$store.state.user.user;
        },

        userStatus () {
            return this.$store.state.user.status;
        },

        shop () {
            return (this.type === "products") ?
                this.$store.state.mall.shop :
                this.$store.state.mall.salon;
        },

        order () {
            const storeMap = {
                "products": "shop",
                "treatments": "salon"
            };
            if (this.orderId) {
                return this.$store.state.mall.orders[storeMap[this.type]]
                    .find(
                        order => order._id === this.orderId
                    );
            }
            return null;
        },
    },
    methods: {

    },
    mounted () {
        this.email = this.$store.state.user.user.email;
        this.phone = this.$store.state.user.user.phone;

        if (this.order) {
            console.log("mounted", this.order);
            if (this.order.status === "accepted") {
                this.$router.push({
                    name: "checkout-completion",
                    params: {
                        orderId: this.orderId
                    }
                });
            }
        }
    },
    watch: {
        "user.email" (newVal) {
            this.email = newVal;
        },

        "user.phone" (newVal) {
            this.phone = newVal;
        },

        "order" (newVal) {
            if (newVal) {
                if (newVal.status === "accepted") {
                    this.$router.push({
                        name: "checkout-completion",
                        params: {
                            orderId: this.orderId
                        }
                    });
                }
            }
        },

        "paymentInfo" (newVal) {
            if (newVal) {
                console.log("paymentInfo", newVal);

                this.$store.dispatch("wallet/setLocalSessionPaymentOptions", newVal);

                if (this.type === "treatments") {
                    this.$store.dispatch(
                        "wallet/setLocalSessionDeliveryOptions",
                        {
                            email: this.email,
                            phone: this.phone,
                        }
                    );
                }
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.checkout-payment {
    .payment-methods {
        min-height: 300px;
        border-top: none;
    }

    .nav-tabs {
        flex-wrap: nowrap;
        overflow-x: auto;

        & > .nav-item {
            & > .nav-link {
                color: rgba(0, 0, 0, 0.38);
                white-space: nowrap;

                &.active {
                    color: rgba(113, 113, 113, 1);
                }
            }
        }
    }
}

.payment-pending-backdrop {
    .sk-cube {
        border-radius: 50%;
    }

    .title {
        color: rgb(255, 255, 255);
        text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.75);
    }
}
</style>
